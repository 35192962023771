export const TOC_LISTING_HEADERS = [
  {
    name: "Revisions",
  },
  {
    name: "Date Created",
  },
  {
    name: "Status",
  },
  {
    name: "Approved On",
  },
  {
    name: "",
  },
];

export const PAC_TYPE = {
  IRF: "IRF",
  SNF: "SNF",
  HH: "HH",
  MPT: "M-PT",
  OPT: "OPT",
  HOME_SERVICE: "Home w/No services",
  EPISODE: "Episode",
};

export enum TocStatusEnum {
  PENDING = "Pending",
  APPROVED = "Approved",
  CANCELLED = "Cancelled",
}

export const NOTE_LIMIT = 2000;

export const ACUTE_LOS_ERROR = "Acute LOS must be between 1 and 50";
export const FACILITY_ERROR = "Select atleast 1 facility or Home w/No services";
export const LOS_VISIT_ERROR =
  "LOS/Visit is required for facility and should be between 1 and ";

export interface ILocation {
  id: string;
  providerId: number;
  providerName: string;
  los: string;
  pacTypeName: string;
  isProviderValid: boolean;
  isLosValid: boolean;
}

export const TOC_ITEMS: Record<string, ILocation> = {
  episode: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.HOME_SERVICE,
    isProviderValid: true,
    isLosValid: true,
  },
  irf: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.IRF,
    isProviderValid: true,
    isLosValid: true,
  },
  snf: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.SNF,
    isProviderValid: true,
    isLosValid: true,
  },
  hh: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.HH,
    isProviderValid: true,
    isLosValid: true,
  },
  mpt: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.MPT,
    isProviderValid: true,
    isLosValid: true,
  },
  opt: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.OPT,
    isProviderValid: true,
    isLosValid: true,
  },
  homeService: {
    id: "",
    providerId: -1,
    providerName: "",
    los: "",
    pacTypeName: PAC_TYPE.HOME_SERVICE,
    isProviderValid: true,
    isLosValid: true,
  },
};

export const LOS_LIMIT: Record<string, number> = {
  IRF: 90,
  SNF: 90,
  HH: 50,
  MPT: 50,
  OPT: 50,
  EPISODE: 100,
};
