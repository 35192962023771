import { ChangeEvent, useEffect, useState } from "react";
import {
  IApproveTocPayload,
  TOCDetailType,
  TOCItemsType,
} from "../toc-detail-types";
import moment from "moment";
import {
  ACUTE_LOS_ERROR,
  FACILITY_ERROR,
  ILocation,
  LOS_LIMIT,
  LOS_VISIT_ERROR,
  NOTE_LIMIT,
  PAC_TYPE,
  TOC_ITEMS,
  TocStatusEnum,
} from "../constants/index.constant";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { getTocs, updateTocDetailsList } from "state/features/toc/toc.slice";
import "./toc-revision.styles.scss";
import Checkbox from "components/checkbox/checkbox.component.tsx";
import { validateNumber } from "shared/methods/utilityFunctions";
import FacilityDropdown from "../facility-dropdown";
import { approveTocRevisionAsync } from "state/features/toc/toc.action";
import ErrorIcon from "shared/assets/images/error.png";
import Modal from "components/Modal";
import Preferred from "components/Preferred/preferred.component";

interface TocRevisionProps {
  toc: TOCDetailType;
}

const TOCRevision = (props: TocRevisionProps) => {
  const dispatch = useDispatch();
  const { facilities } = useSelector(getTocs);
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [selectedLocationValues, setSelectedLocationValues] =
    useState<Record<string, ILocation>>(TOC_ITEMS);
  const [originalLocationValues, setOriginalLocationValues] =
    useState<Record<string, ILocation>>(TOC_ITEMS);
  const [isHomeWithNoServicesEnabled, setIsHomeWithNoServicesEnabled] =
    useState(false);
  const [acuteLos, setAcuteLos] = useState<{ value: string; error: string }>({
    value: "",
    error: "",
  });
  const { tocDetails, pacTypes, isLoading } = useSelector(getTocs);
  const { toc } = props;
  const {
    version,
    createdDate,
    approvedOn,
    isApproved,
    isExpanded,
    id,
    tocItems,
    procedure,
    notesFromNavigator,
    notesFromPhysician,
    status,
  } = toc;
  const [notes, setNotes] = useState<string | null>(notesFromPhysician ?? null);
  const [facilityError, setFacilityError] = useState<string>("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (tocItems && tocItems.length > 0) {
      let locations = TOC_ITEMS;
      tocItems.forEach(
        ({
          pacTypeName,
          id,
          providerId,
          providerName,
          targetLOS,
        }: TOCItemsType) => {
          if (pacTypeName === PAC_TYPE.EPISODE) {
            locations = {
              ...locations,
              episode: {
                id,
                providerId,
                providerName: providerName ?? "",
                los: targetLOS !== -1 ? targetLOS.toString() : "",
                pacTypeName: PAC_TYPE.EPISODE,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          }
          if (pacTypeName === PAC_TYPE.SNF) {
            locations = {
              ...locations,
              snf: {
                id,
                providerId,
                providerName: providerName ?? "",
                los: targetLOS !== -1 ? targetLOS.toString() : "",
                pacTypeName: PAC_TYPE.SNF,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          } else if (pacTypeName === PAC_TYPE.IRF) {
            locations = {
              ...locations,
              irf: {
                id,
                providerId,
                providerName: providerName ?? "",
                los: targetLOS !== -1 ? targetLOS.toString() : "",
                pacTypeName: PAC_TYPE.IRF,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          }
          if (pacTypeName === PAC_TYPE.HH) {
            locations = {
              ...locations,
              hh: {
                id,
                providerId,
                providerName: providerName ?? "",
                los: targetLOS !== -1 ? targetLOS.toString() : "",
                pacTypeName: PAC_TYPE.HH,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          }
          if (pacTypeName === PAC_TYPE.OPT) {
            locations = {
              ...locations,
              opt: {
                id,
                providerId,
                providerName: providerName ?? "",
                los: targetLOS !== -1 ? targetLOS.toString() : "",
                pacTypeName: PAC_TYPE.OPT,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          }
          if (pacTypeName === PAC_TYPE.MPT) {
            locations = {
              ...locations,
              mpt: {
                id,
                providerId,
                providerName: providerName ?? "",
                los: targetLOS !== -1 ? targetLOS.toString() : "",
                pacTypeName: PAC_TYPE.MPT,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          }
          if (pacTypeName === PAC_TYPE.HOME_SERVICE) {
            locations = {
              ...locations,
              homeService: {
                id,
                providerId,
                providerName: providerName ?? "",
                los: targetLOS !== -1 ? targetLOS.toString() : "",
                pacTypeName: PAC_TYPE.HOME_SERVICE,
                isLosValid: true,
                isProviderValid: true,
              },
            };
          }

          setSelectedLocationValues(locations);
          setOriginalLocationValues(locations);
          if (
            pacTypeName === PAC_TYPE.HOME_SERVICE &&
            providerId === 0 &&
            targetLOS === 0
          ) {
            setIsHomeWithNoServicesEnabled(true);
          }
        }
      );
    }
    if (procedure.acuteLos.toString()) {
      setAcuteLos({ value: procedure.acuteLos.toString(), error: "" });
    }
  }, []);

  const handleTocExpand = () => {
    const tocId = id;
    const newTocs: TOCDetailType[] = [];
    if (isApproved) {
      tocDetails.forEach((item: any) => {
        const updatedItem = { ...item };
        if (item.approvedOn) {
          if (item.id === tocId) {
            updatedItem.isExpanded = !item.isExpanded;
          } else {
            updatedItem.isExpanded = false;
          }
        }

        newTocs.push(updatedItem as TOCDetailType);
      });
    } else {
      tocDetails.forEach((item: any) => {
        const updatedItem = { ...item };
        if (!item.approvedAt) {
          if (item.id === tocId) {
            updatedItem.isExpanded = !item.isExpanded;
          }
        }

        newTocs.push(updatedItem as TOCDetailType);
      });
    }

    dispatch(updateTocDetailsList(newTocs));
  };

  const onChangeAcuteLos = (e: ChangeEvent<HTMLInputElement>) => {
    setAcuteLos({
      ...acuteLos,
      value: e.target.value,
      error: "",
    });
  };

  const onNotesChange = (val: string) => {
    setNotes(val);
  };

  const checkForLocationError = (providerId: number, los: string) => {
    let isProviderValid = false;
    let isLosValid = false;
    if (!los && providerId === -1) {
      isProviderValid = true;
      isLosValid = true;
    } else if (los && providerId === -1) {
      isProviderValid = false;
      isLosValid = true;
    } else if (!los && providerId !== -1) {
      isProviderValid = true;
      isLosValid = false;
    } else if (los && providerId !== -1) {
      isProviderValid = true;
      isLosValid = true;
    }

    return [isProviderValid, isLosValid];
  };

  const onFacilityChange = (
    key: string,
    value: { providerId: number; providerName: string }
  ) => {
    setFacilityError("");

    const location = selectedLocationValues[key];
    let [isProviderValid, isLosValid] = [true, true];
    if (!location.isLosValid || !location.isProviderValid) {
      [isProviderValid, isLosValid] = checkForLocationError(
        value.providerId,
        location.los
      );
    }
    setSelectedLocationValues((prev: any) => {
      return {
        ...prev,
        [key]: {
          ...prev[key],
          ...value,
          isProviderValid,
          isLosValid,
        },
      };
    });
  };

  const onChangeVisitCount = (key: string, value: string) => {
    setFacilityError("");

    const location = selectedLocationValues[key];
    let [isProviderValid, isLosValid] = [true, true];
    if (!location.isLosValid || !location.isProviderValid) {
      [isProviderValid, isLosValid] = checkForLocationError(
        selectedLocationValues[key].providerId,
        value
      );
    }
    setSelectedLocationValues((prev: any) => {
      return {
        ...prev,
        [key]: {
          ...prev[key],
          los: value,
          isLosValid,
          isProviderValid,
        },
      };
    });
  };

  const checkIfNoteValid = () => {
    if (notes) {
      return notes.length <= NOTE_LIMIT;
    }
    return true;
  };

  const checkIfAcuteLosValid = (value: string) => {
    if (!value) {
      return false;
    }
    const acuteLosNum = parseInt(value, 10);
    if (acuteLosNum < 1 || acuteLosNum > 50) {
      return false;
    }
    return true;
  };

  const checkIfLocationsValid = () => {
    let isValid = false;
    Object.values(selectedLocationValues).forEach((location) => {
      isValid = isValid || (!!location.providerId && !!location.providerName);
    });
    return isValid;
  };

  const checkIfLosForLocationsValid = () => {
    let isValid = true;
    Object.entries(selectedLocationValues).forEach(
      ([key, location]: [string, ILocation]) => {
        if (location.providerId && location.providerName) {
          const isLosValid =
            !!location.los &&
            0 < parseInt(location.los, 10) &&
            parseInt(location.los, 10) <= LOS_LIMIT[key.toUpperCase()];
          isValid = isValid && isLosValid;
          if (!isLosValid) {
            setSelectedLocationValues((prev: any) => {
              return {
                ...prev,
                [key]: {
                  ...prev[key],
                  isLosValid: false,
                },
              };
            });
          } else {
            setSelectedLocationValues((prev: any) => {
              return {
                ...prev,
                [key]: {
                  ...prev[key],
                  isLosValid: true,
                },
              };
            });
          }
        }
      }
    );
    return isValid;
  };

  const checkIfTocItemsValid = () => {
    let isValid = true;
    Object.entries(selectedLocationValues)
      .filter((el) => el[1].pacTypeName !== PAC_TYPE.HOME_SERVICE)
      .forEach(([key, location]) => {
        if (location.providerId !== -1 || !!location.los) {
          const bothExists = location.providerId !== -1 && !!location.los;
          isValid = isValid && bothExists;
          if (!bothExists) {
            setSelectedLocationValues((prev: any) => {
              return {
                ...prev,
                [key]: {
                  ...prev[key],
                  isProviderValid: location.providerId !== -1,
                  isLosValid: location.los.length > 0,
                },
              };
            });
          }
        }
      });

    return isValid;
  };

  const getLosVisitsError = (facilityName: string) => {
    return (
      <span>
        <img src={ErrorIcon} alt="Error" />
        {LOS_VISIT_ERROR + LOS_LIMIT[facilityName]}
      </span>
    );
  };

  const getProviderError = (facilityName: string) => {
    return (
      <span>
        <img src={ErrorIcon} alt="Error" />
        {`Enter a facility for ${facilityName}`}
      </span>
    );
  };

  const checkMandatoryValues = () => {
    const isActuteLosValid = checkIfAcuteLosValid(acuteLos.value);
    setAcuteLos({
      ...acuteLos,
      error: isActuteLosValid ? "" : ACUTE_LOS_ERROR,
    });

    const isNoteValid = checkIfNoteValid();
    if (isHomeWithNoServicesEnabled) {
      return isActuteLosValid && isNoteValid;
    }

    let isTocFormEmpty = true;
    Object.values(selectedLocationValues)
      .filter((el) => el.pacTypeName !== PAC_TYPE.HOME_SERVICE)
      .forEach((location) => {
        isTocFormEmpty =
          isTocFormEmpty && !location.los && location.providerId === -1;
      });

    if (isTocFormEmpty) {
      setFacilityError(FACILITY_ERROR);
      return false;
    } else {
      setFacilityError("");
    }

    const isLocationsValid = checkIfLocationsValid();
    const areLosForLocationsValid = checkIfLosForLocationsValid();
    const isTocItemsValid = checkIfTocItemsValid();

    return (
      isNoteValid &&
      isActuteLosValid &&
      isLocationsValid &&
      isTocItemsValid &&
      areLosForLocationsValid
    );
  };

  const isTocDirty = () => {
    const currentValues = Object.values(selectedLocationValues);
    const originalValues = Object.values(originalLocationValues);

    const currentIrf = currentValues.find(
      (el) => el.pacTypeName === PAC_TYPE.IRF
    );
    const originalIrf = originalValues.find(
      (el) => el.pacTypeName === PAC_TYPE.IRF
    );

    if (!!currentIrf && !!originalIrf) {
      if (
        currentIrf.providerId !== originalIrf.providerId ||
        currentIrf.los !== originalIrf.los
      ) {
        return true;
      }
    }

    const currentSnf = currentValues.find(
      (el) => el.pacTypeName === PAC_TYPE.SNF
    );
    const originalSnf = originalValues.find(
      (el) => el.pacTypeName === PAC_TYPE.SNF
    );

    if (!!currentSnf && !!originalSnf) {
      if (
        currentSnf.providerId !== originalSnf.providerId ||
        currentSnf.los !== originalSnf.los
      ) {
        return true;
      }
    }

    const currentHh = currentValues.find(
      (el) => el.pacTypeName === PAC_TYPE.HH
    );
    const originalHh = originalValues.find(
      (el) => el.pacTypeName === PAC_TYPE.HH
    );

    if (!!currentHh && !!originalHh) {
      if (
        currentHh.providerId !== originalHh.providerId ||
        currentHh.los !== originalHh.los
      ) {
        return true;
      }
    }

    const currentOpt = currentValues.find(
      (el) => el.pacTypeName === PAC_TYPE.OPT
    );
    const originalOpt = originalValues.find(
      (el) => el.pacTypeName === PAC_TYPE.OPT
    );

    if (!!currentOpt && !!originalOpt) {
      if (
        currentOpt.providerId !== originalOpt.providerId ||
        currentOpt.los !== originalOpt.los
      ) {
        return true;
      }
    }

    const currentMpt = currentValues.find(
      (el) => el.pacTypeName === PAC_TYPE.MPT
    );
    const originalMpt = originalValues.find(
      (el) => el.pacTypeName === PAC_TYPE.MPT
    );

    if (!!currentMpt && !!originalMpt) {
      if (
        currentMpt.providerId !== originalMpt.providerId ||
        currentMpt.los !== originalMpt.los
      ) {
        return true;
      }
    }

    const currentHomeService = currentValues.find(
      (el) => el.pacTypeName === PAC_TYPE.HOME_SERVICE
    );
    const originalHomeService = originalValues.find(
      (el) => el.pacTypeName === PAC_TYPE.HOME_SERVICE
    );

    if (!!currentHomeService && !!originalHomeService) {
      if (
        currentHomeService.providerId !== originalHomeService.providerId ||
        currentHomeService.los !== originalHomeService.los
      ) {
        return true;
      }
    }

    if (procedure.acuteLos.toString() !== acuteLos.value) {
      return true;
    }

    return false;
  };

  const preparePayload = () => {
    const hwnPacType = pacTypes.find(
      (el) => el.itemShortName === PAC_TYPE.HOME_SERVICE
    );
    const episodePacType = pacTypes.find(
      (el) => el.itemShortName === selectedLocationValues.episode.pacTypeName
    );
    const changedValues = isHomeWithNoServicesEnabled
      ? [
          {
            PACTypeName: PAC_TYPE.HOME_SERVICE,
            id: -1,
            ProviderID: 0,
            TargetLOS: 0,
            PACTypeId: hwnPacType?.id,
          },
          {
            PACTypeName: selectedLocationValues.episode.pacTypeName,
            id: selectedLocationValues.episode.id,
            ProviderID: selectedLocationValues.episode.providerId,
            TargetLOS: selectedLocationValues.episode.los,
            PACTypeId: episodePacType?.id,
          },
        ]
      : Object.values(selectedLocationValues)
          .filter((item: ILocation) => item.providerId !== -1 && !!item.los)
          .map((item: ILocation) => {
            const pacType = pacTypes.find(
              (el) => el.itemShortName === item.pacTypeName
            );
            return {
              id: item.id === "" ? crypto.randomUUID() : item.id,
              PACTypeName: item.pacTypeName,
              PACTypeId: pacType?.id,
              ProviderID: item.providerId,
              ProviderName: item.providerName,
              TargetLOS: item.los,
            };
          });

    return {
      id,
      approved: true,
      isModified: isTocDirty(),
      notePhysician: notes,
      anticipatedAcuteLOS: acuteLos.value,
      transitionOfCareItems: changedValues,
      primaryCareManagerEmail: toc.careManagerEmail,
      practiceName: toc.practiceName,
      physicianName: toc.physicianName,
    };
  };

  const approveToc = () => {
    if (isConfirmModalOpen) {
      const payload: IApproveTocPayload = preparePayload();
      dispatch(approveTocRevisionAsync(payload));
      setIsConfirmModalOpen(false);
    }
  };

  const resetLocationValidation = () => {
    const formClone = { ...selectedLocationValues };
    Object.values(formClone).forEach((location) => {
      location.isLosValid = true;
      location.isProviderValid = true;
    });
  };

  const isPreferredFacility = (facilityId?: number) => {
    const preferredFacility = facilities.find(
      (item) => item.id === facilityId
    )?.preferredProvider;
    return preferredFacility;
  };

  const renderTocDetailsInReadMode = () => {
    const { irf, snf, hh, opt, mpt } = selectedLocationValues;
    return (
      <div className="toc-approve-info-readonly">
        <div className="toc-content">
          <div className="toc-plan">
            <div className="episode-details">
              <div className="toc-plan-heading">TOC Plan</div>
              <div className="episode-details-container">
                <div className="toc-episode-info">
                  <div className="toc-plan-episode">
                    {procedure.procedureName ?? "-"}
                  </div>
                  <div className="toc-plan-facility">
                    {procedure.facilityName ?? "-"}
                  </div>
                </div>
                <div className="toc-plan-los-container">
                  <div className="acute-los-heading">Acute LOS</div>
                  <div className="acute-los-readonly">{acuteLos.value}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="location-grid-container">
            <div className="location-dropdowns">
              <div className="location-row-header">
                <div className="location">Location</div>
                <div className="location-name">Location Name</div>
                <div className="los">LOS/Visits</div>
              </div>

              <div className="location-row">
                <div className="location-value">IRF</div>
                <div className="toc-dropdown-readonly">
                  {irf.providerName.length ? irf.providerName : "-"}
                  {isPreferredFacility(irf.providerId) && <Preferred />}
                </div>
                <div className="toc-visits-readonly">
                  {irf?.los?.length ? irf.los : "-"}
                </div>
              </div>

              <div className="location-row">
                <div className="location-value">SNF</div>
                <div className="toc-dropdown-readonly">
                  {snf.providerName.length ? snf.providerName : "-"}
                  {isPreferredFacility(snf.providerId) && <Preferred />}
                </div>
                <div className="toc-visits-readonly">
                  {snf?.los?.length ? snf.los : "-"}
                </div>
              </div>

              <div className="location-row">
                <div className="location-value">HH</div>
                <div className="toc-dropdown-readonly">
                  {hh.providerName.length ? hh.providerName : "-"}
                  {isPreferredFacility(hh.providerId) && <Preferred />}
                </div>
                <div className="toc-visits-readonly">
                  {hh?.los?.length ? hh.los : "-"}
                </div>
              </div>

              <div className="location-row">
                <div className="location-value">M-PT</div>
                <div className="toc-dropdown-readonly">
                  {mpt.providerName.length ? mpt.providerName : "-"}
                  {isPreferredFacility(mpt.providerId) && <Preferred />}
                </div>
                <div className="toc-visits-readonly">
                  {mpt?.los?.length ? mpt.los : "-"}
                </div>
              </div>

              <div className="location-row">
                <div className="location-value">OPT</div>
                <div className="toc-dropdown-readonly">
                  {opt.providerName.length ? opt.providerName : "-"}
                  {isPreferredFacility(opt.providerId) && <Preferred />}
                </div>
                <div className="toc-visits-readonly">
                  {opt?.los?.length ? opt.los : "-"}
                </div>
              </div>
            </div>

            {isHomeWithNoServicesEnabled ? (
              <div className="checkbox-container">
                <div className="checkboxes" key="Incoming-checkox">
                  <Checkbox
                    name="homeWithoutServices"
                    value="Home w/No services"
                    id="Incoming-checkox"
                    onChange={() => {}}
                    isDisabled
                    isChecked={isHomeWithNoServicesEnabled}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="note-container">
          <div className="navigator-notes">
            <div className="note-heading">Navigator Notes</div>
            <div className="note-textarea-readonly">
              {notesFromNavigator && notesFromNavigator.length
                ? notesFromNavigator
                : "-"}
            </div>
          </div>

          <div className="physician-notes">
            <div className="note-heading">Physician Notes</div>
            <div className="note-textarea-readonly">
              {notesFromPhysician && notesFromPhysician.length
                ? notesFromPhysician
                : "-"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTocDetailsInEditMode = () => {
    const { irf, snf, hh, opt, mpt } = selectedLocationValues;
    return (
      <>
        <div className="toc-approve-info">
          <div className="toc-content">
            <div className="toc-plan">
              <div className="episode-details">
                <div className="toc-plan-heading">TOC Plan</div>
                <div className="episode-details-container">
                  <div className="toc-episode-info">
                    <div className="toc-plan-episode">
                      {procedure.procedureName ?? "-"}
                    </div>
                    <div className="toc-plan-facility">
                      {procedure.facilityName ?? "-"}
                    </div>
                  </div>
                  <div className="toc-plan-los-container">
                    <div className="acute-los-heading">Acute LOS</div>
                    <div className="acute-los">
                      <input
                        className={`actue-los-input ${
                          !acuteLos.error ? null : "input-error"
                        }`}
                        type="string"
                        placeholder="0"
                        name="acute-los"
                        value={acuteLos.value}
                        maxLength={2}
                        onChange={(e: any) => {
                          if (
                            (validateNumber(e.target.value) ||
                              e.target.value === "") &&
                            e.target.value !== "0"
                          ) {
                            onChangeAcuteLos(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (["e", ".", "-", "+"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="toc-plan-error">
                {!acuteLos.error ? null : (
                  <span>
                    <img src={ErrorIcon} alt="Error" />
                    {acuteLos.error}
                  </span>
                )}
                {!facilityError ? null : (
                  <span>
                    <img src={ErrorIcon} alt="Error" />
                    {facilityError}
                  </span>
                )}
              </div>
            </div>
            <div className="location-grid-container">
              <div className="location-dropdowns">
                <div className="location-row-header">
                  <div className="location">Location</div>
                  <div className="location-name">Location Name</div>
                  <div className="los">LOS/Visits</div>
                </div>

                <div className="location-row">
                  <div className="location-content">
                    <div className="location-value">IRF</div>
                    <div className="toc-dropdown">
                      <FacilityDropdown
                        selectedOptionId={irf.providerId}
                        selectedOptionName={irf.providerName}
                        isError={!irf.isProviderValid}
                        onChange={(
                          providerId: number,
                          providerName: string
                        ) => {
                          onFacilityChange("irf", { providerId, providerName });
                        }}
                        isDisabled={isHomeWithNoServicesEnabled}
                      />
                    </div>
                    <div className="toc-visits">
                      <input
                        type="string"
                        placeholder="0"
                        name={PAC_TYPE.IRF}
                        value={irf.los}
                        maxLength={2}
                        onChange={(e: any) => {
                          if (
                            (validateNumber(e.target.value) ||
                              e.target.value === "") &&
                            e.target.value !== "0"
                          ) {
                            onChangeVisitCount("irf", e.target.value);
                          }
                        }}
                        disabled={isHomeWithNoServicesEnabled}
                        className={`visits-input ${
                          irf.isLosValid ? "" : "input-error"
                        }`}
                        onKeyDown={(e) => {
                          if (["e", ".", "-", "+"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="location-error">
                    {!irf.isProviderValid ? getProviderError("IRF") : null}
                    {!irf.isLosValid ? getLosVisitsError("IRF") : null}
                  </div>
                </div>

                <div className="location-row">
                  <div className="location-content">
                    <div className="location-value">SNF</div>
                    <div className="toc-dropdown">
                      <FacilityDropdown
                        selectedOptionId={snf.providerId}
                        selectedOptionName={snf.providerName}
                        isError={!snf.isProviderValid}
                        onChange={(
                          providerId: number,
                          providerName: string
                        ) => {
                          onFacilityChange("snf", { providerId, providerName });
                        }}
                        isDisabled={isHomeWithNoServicesEnabled}
                      />
                    </div>
                    <div className="toc-visits">
                      <input
                        type="string"
                        placeholder="0"
                        name={PAC_TYPE.SNF}
                        value={snf.los}
                        maxLength={2}
                        onChange={(e: any) => {
                          if (
                            (validateNumber(e.target.value) ||
                              e.target.value === "") &&
                            e.target.value !== "0"
                          ) {
                            onChangeVisitCount("snf", e.target.value);
                          }
                        }}
                        className={`visits-input ${
                          snf.isLosValid ? "" : "input-error"
                        }`}
                        disabled={isHomeWithNoServicesEnabled}
                        onKeyDown={(e) => {
                          if (["e", ".", "-", "+"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="location-error">
                    {!snf.isProviderValid ? getProviderError("SNF") : null}
                    {!snf.isLosValid ? getLosVisitsError("SNF") : null}
                  </div>
                </div>

                <div className="location-row">
                  <div className="location-content">
                    <div className="location-value">HH</div>
                    <div className="toc-dropdown">
                      <FacilityDropdown
                        selectedOptionId={hh.providerId}
                        selectedOptionName={hh.providerName}
                        isError={!hh.isProviderValid}
                        onChange={(
                          providerId: number,
                          providerName: string
                        ) => {
                          onFacilityChange("hh", { providerId, providerName });
                        }}
                        isDisabled={isHomeWithNoServicesEnabled}
                      />
                    </div>
                    <div className="toc-visits">
                      <input
                        type="string"
                        placeholder="0"
                        name={PAC_TYPE.HH}
                        value={hh.los}
                        maxLength={2}
                        onChange={(e: any) => {
                          if (
                            (validateNumber(e.target.value) ||
                              e.target.value === "") &&
                            e.target.value !== "0"
                          ) {
                            onChangeVisitCount("hh", e.target.value);
                          }
                        }}
                        className={`visits-input ${
                          hh.isLosValid ? "" : "input-error"
                        }`}
                        disabled={isHomeWithNoServicesEnabled}
                        onKeyDown={(e) => {
                          if (["e", ".", "-", "+"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="location-error">
                    {!hh.isProviderValid ? getProviderError("HH") : null}
                    {!hh.isLosValid ? getLosVisitsError("HH") : null}
                  </div>
                </div>

                <div className="location-row">
                  <div className="location-content">
                    <div className="location-value">M-PT</div>
                    <div className="toc-dropdown">
                      <FacilityDropdown
                        selectedOptionId={mpt.providerId}
                        selectedOptionName={mpt.providerName}
                        isError={!mpt.isProviderValid}
                        onChange={(
                          providerId: number,
                          providerName: string
                        ) => {
                          onFacilityChange("mpt", { providerId, providerName });
                        }}
                        isDisabled={isHomeWithNoServicesEnabled}
                      />
                    </div>
                    <div className="toc-visits">
                      <input
                        type="string"
                        placeholder="0"
                        name={PAC_TYPE.MPT}
                        value={mpt.los}
                        maxLength={2}
                        onChange={(e: any) => {
                          if (
                            (validateNumber(e.target.value) ||
                              e.target.value === "") &&
                            e.target.value !== "0"
                          ) {
                            onChangeVisitCount("mpt", e.target.value);
                          }
                        }}
                        className={`visits-input ${
                          mpt.isLosValid ? "" : "input-error"
                        }`}
                        disabled={isHomeWithNoServicesEnabled}
                        onKeyDown={(e) => {
                          if (["e", ".", "-", "+"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="location-error">
                    {!mpt.isProviderValid ? getProviderError("M-PT") : null}
                    {!mpt.isLosValid ? getLosVisitsError("MPT") : null}
                  </div>
                </div>

                <div className="location-row">
                  <div className="location-content">
                    <div className="location-value">OPT</div>
                    <div className="toc-dropdown">
                      <FacilityDropdown
                        selectedOptionId={opt.providerId}
                        selectedOptionName={opt.providerName}
                        isError={!opt.isProviderValid}
                        onChange={(
                          providerId: number,
                          providerName: string
                        ) => {
                          onFacilityChange("opt", { providerId, providerName });
                        }}
                        isDisabled={isHomeWithNoServicesEnabled}
                      />
                    </div>
                    <div className="toc-visits">
                      <input
                        type="string"
                        placeholder="0"
                        name={PAC_TYPE.OPT}
                        value={opt.los}
                        maxLength={2}
                        onChange={(e: any) => {
                          if (
                            (validateNumber(e.target.value) ||
                              e.target.value === "") &&
                            e.target.value !== "0"
                          ) {
                            onChangeVisitCount("opt", e.target.value);
                          }
                        }}
                        className={`visits-input ${
                          opt.isLosValid ? "" : "input-error"
                        }`}
                        disabled={isHomeWithNoServicesEnabled}
                        onKeyDown={(e) => {
                          if (["e", ".", "-", "+"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="location-error">
                    {!opt.isProviderValid ? getProviderError("OPT") : null}
                    {!opt.isLosValid ? getLosVisitsError("OPT") : null}
                  </div>
                </div>
              </div>

              <div className="checkbox-container">
                <div className="checkboxes" key="Incoming-checkox">
                  <Checkbox
                    name="homeWithoutServices"
                    value="Home w/No services"
                    id="Incoming-checkox"
                    onChange={() => {
                      resetLocationValidation();
                      setIsHomeWithNoServicesEnabled(
                        !isHomeWithNoServicesEnabled
                      );
                      if (!isHomeWithNoServicesEnabled) {
                        setSelectedLocationValues((prev) => {
                          return {
                            ...prev,
                            homeService: {
                              ...prev.homeService,
                              providerId: 0,
                              los: "0",
                            },
                          };
                        });
                      } else {
                        setSelectedLocationValues((prev) => {
                          return {
                            ...prev,
                            homeService: {
                              ...prev.homeService,
                              providerId: -1,
                              los: "-1",
                            },
                          };
                        });
                      }
                    }}
                    isChecked={isHomeWithNoServicesEnabled}
                    isDisabled={false}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="note-container">
            <div className="navigator-notes">
              <div className="note-heading">Navigator Notes</div>
              <div className="note-textarea-readonly">
                {notesFromNavigator && notesFromNavigator.length
                  ? notesFromNavigator
                  : "-"}
              </div>
            </div>

            <div className="physician-notes">
              <div className="note-heading">Physician Notes</div>
              <textarea
                className="note-textarea"
                value={notes ?? ""}
                onChange={(e: any) => {
                  onNotesChange(e.target.value);
                }}
                maxLength={2000}
                placeholder="Write your notes here..."
                cols={5}
              />
              <div className="note-hint">
                Characters limit: <span className="note-limit">2000</span>
              </div>
            </div>
          </div>
        </div>
        <div className="last-row-with-approve-button">
          <div className="cta-container">
            <Button
              text="Approve"
              className={`green-button submit-button `}
              buttonWithLoader
              isLoading={isLoading}
              onClick={() => {
                if (checkMandatoryValues()) {
                  setIsConfirmModalOpen(true);
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal isOpen={isConfirmModalOpen} className="center">
        <div className="logout-container">
          <div className="header">Approve TOC</div>
          <div className="content">
            Are you sure you want to approve this TOC?
          </div>
          <div className="button-section">
            <Button
              text="Cancel"
              className="white-button cancel-button"
              onClick={() => {
                setIsConfirmModalOpen(false);
              }}
            />
            <Button
              text="Confirm"
              className="green-button reload-button"
              onClick={() => {
                approveToc();
              }}
            />
          </div>
        </div>
      </Modal>
      <div
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
        className={`toc-row ${isRowHovered ? "hovered" : ""} ${
          isExpanded ? "opened" : ""
        }`}
      >
        <div className="toc-cell">
          {version === 1 ? "Original TOC" : `Revision ${version - 1}`}
        </div>
        <div className="toc-cell">
          {createdDate ? moment(createdDate).format("MM/DD/YYYY") : "-"}
        </div>
        <div
          className={`toc-cell ${
            status === TocStatusEnum.PENDING ? "pending-status" : ""
          }`}
        >
          {status}
        </div>
        <div className="toc-cell">
          {approvedOn ? moment(approvedOn).format("MM/DD/YYYY") : "-"}
        </div>
        <div
          className={`toc-cell icons ${
            isRowHovered || isExpanded ? "visible" : "hidden"
          }`}
        >
          {isExpanded ? (
            <Button
              icon="up-arrow"
              onClick={() => {
                handleTocExpand();
              }}
              buttonSize={10}
              className="icon-background"
            />
          ) : (
            <Button
              icon="down-arrow"
              onClick={() => {
                handleTocExpand();
              }}
              buttonSize={10}
              className="icon-background"
            />
          )}
        </div>
      </div>
      {isExpanded ? (
        <>
          {status === TocStatusEnum.PENDING
            ? renderTocDetailsInEditMode()
            : renderTocDetailsInReadMode()}
        </>
      ) : null}
    </>
  );
};

export default TOCRevision;
