import React, { useEffect, useState } from "react";
import "./login-form.styles.scss";
import { LOGIN_FORM } from "../../../shared/constant/constants";
import TextField from "components/TextField";
import Icon from "components/Icon";
import Button from "components/Button";
import { ButtonLoader } from "components/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuth,
  setError,
  setIsLoading,
} from "state/features/authentication/authentication.slice";
// import { ButtonLoader } from 'src/app/components/ButtonLoader';

type LoginFormProps = {
  onLoginClick: React.MouseEventHandler<HTMLButtonElement>;
  value: string;
  setValue: (value: string) => void;
  isButtonActive: boolean;
  setButtonActive: (value: boolean) => void;
  isLoggedIn: boolean;
  passwordValue: string;
  setPasswordValue: (value: string) => void;
  onClickResetPassword: () => void;
};

export const LoginForm = ({
  onLoginClick,
  value,
  setValue,
  isButtonActive,
  setButtonActive,
  isLoggedIn,
  passwordValue,
  setPasswordValue,
  onClickResetPassword,
}: LoginFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const authState = useSelector(getAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value /*&& validateEmail(value)*/ && passwordValue.length >= 8) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }
  }, [value, setValue, setButtonActive, setError, passwordValue]);

  const togglePassword = () => {
    setShowPassword((prev) => {
      const element = document.querySelector(
        ".password-input"
      ) as HTMLInputElement;
      if (!prev) {
        element.type = "text";
      } else {
        element.type = "password";
      }
      return !prev;
    });
  };

  return (
    <div
      className={`loginFormWrapper ${
        isLoggedIn === false ? "" : "loginFormWrapperTransform"
      }`}
    >
      <form className="login-form">
        <div className="login-field-wrapper">
          <TextField
            label="User Name"
            className="input-field"
            inputClassName="input"
            inputContainerClassName="login-input-container"
            type="text"
            value={value}
            setValue={(label, value) => setValue(value)}
            icon={<Icon icon="username" size={16} className="username-icon" />}
            placeholder={LOGIN_FORM.emailPlaceholder}
          />
          <TextField
            label="Password"
            className="input-field"
            inputContainerClassName="login-input-container"
            inputClassName="password-input"
            type="password"
            value={passwordValue}
            setValue={(label, value) => setPasswordValue(value)}
            icon={<Icon icon="password" size={19} className="username-icon" />}
            placeholder={LOGIN_FORM.passwordPlaceholder}
            togglePassword={togglePassword}
            isVisiblePassword={showPassword}
          />
        </div>
        {/* {showError && <div className="error-wrap">Error</div>} */}
        <div className="login-button-wrapper">
          <Button
            text="Forgot Password?"
            onClick={onClickResetPassword}
            className="forgot-password no-border-button icon-button"
          />
          <div className="login-button-container">
            <button
              className={`login-button ${isButtonActive ? "" : "disabled"}`}
              onClick={onLoginClick}
            >
              {authState.isLoading ? (
                <ButtonLoader />
              ) : (
                <span className="login-button-screen">
                  {LOGIN_FORM.buttonLabel}
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
