import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosPrivate } from "shared/api/axios-wrapper.service";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { setTocSuggestions } from "./toc.slice";
import { getConfig } from "../../../config/config";
import {
  IApproveTocPayload,
  TOCItemsType,
} from "pages/toc-detail/toc-detail-types";
import { IFacility } from "state/types/toc-slice.type";

export const fetchTransitionOfCareItemsAsync = createAsyncThunk(
  "toc/fetchTransitionOfCareItemsAsync",
  async (requestPayload: any, { dispatch, signal }) => {
    const response = await axiosPrivate.post(
      `${getConfig().efusionBase}/api/v2/ExtTransitionOfCare/getbyprovider`,
      requestPayload,
      { signal }
    );
    if (response.status === 200) {
      if (
        (response.data.Count.Pending > 0 || response.data.Count.Approved > 0) &&
        !isEmptyString(requestPayload.patientNameFilter) &&
        requestPayload.patientNameFilter.length >= 2
      ) {
        const pendingList = response.data.ToCList.Pending.filter((item: any) =>
          item.PatientName.toLowerCase().startsWith(
            requestPayload.patientNameFilter
          )
        );
        const approvedList = response.data.ToCList.Approved.filter(
          (item: any) =>
            item.PatientName.toLowerCase().startsWith(
              requestPayload.patientNameFilter
            )
        );
        if (pendingList.length > 0) {
          dispatch(
            setTocSuggestions(
              pendingList[0].PatientName.toLowerCase().split(" ")[0]
            )
          );
        } else if (approvedList.length > 0) {
          dispatch(
            setTocSuggestions(
              approvedList[0].PatientName.toLowerCase().split(" ")[0]
            )
          );
        }
      } else {
        dispatch(setTocSuggestions(""));
      }
    }
    return response.data;
  }
);

export const fetchTransitionOfCareDetailsAsync = createAsyncThunk(
  "toc/fetchTransitionOfCareDetailsAsync",
  async (requestPayload: any) => {
    const response = await axiosPrivate.get(
      `${
        getConfig().efusionBase
      }/api/v2/ExtTransitionOfCare/getbyintake/${requestPayload}`
    );
    if (response?.status === 200) {
      const versions = response.data;
      if (versions && versions.length > 0) {
        const versionsMapped = versions.map((v: any) => {
          return {
            id: v.ID,
            version: v.Version,
            intakeId: v.IntakeID,
            clientId: v.ClientID,
            createdDate: v.RecordCreated,
            status: v.Status,
            isApproved: v.ReviewedWithProvider,
            approvedOn: v.ReviewedWithProviderDate,
            approvedBy: v.ReviewedWithProviderByFormattedName,
            notesFromNavigator: v.NoteCareManager,
            notesFromPhysician: v.NotePhysician,
            careManagerId: v.Intake.PrimaryCareManagerID,
            careManagerName: v.Intake.PrimaryCareManagerName,
            careManagerEmail: v.Intake.PrimaryCareManagerEmail,
            practiceName: v.Practice?.LongName,
            physicianName:
              v.Surgeon?.FirstName + " " + v.Surgeon?.LastName ?? "",
            patient: {
              id: v.Patient.ID,
              userId: v.Patient.UserId,
              name: `${v.Patient.FirstName} ${v.Patient.LastName}`,
              dob: v.Patient.DOB,
              gender: v.Patient.Gender,
              age: v.Patient.Age,
              currentLocation: v.Intake.PatientLocationName,
            },
            procedure: {
              surgeryDate: v.Intake.SurgeryDate,
              admitDate: v.Intake.AdmitDate,
              careManagerId: v.Intake.PrimaryCareManagerID,
              careManagerName: v.Intake.PrimaryCareManagerName,
              episodeId: v.Intake.Episode.ID,
              procedureName: v.Intake.Episode.LongName,
              procedureDescription: v.Intake.ProcedureDescription,
              facilityName: v.Facility?.ProviderName,
              acuteLos: v.AnticipatedAcuteLOS,
            },
            tocItems: v.TransitionOfCareItems.map(
              (el: any) =>
                ({
                  id: el.ID,
                  transitionOfCareId: el.TransitionOfCareID,
                  pacTypeId: el.PACTypeID,
                  targetLOS: el.TargetLOS,
                  pacTypeName: el.PACTypeName,
                  providerId: el.ProviderID,
                  providerName: el.ProviderName,
                  recordCreated: el.RecordCreated,
                  recordCreatedBy: el.RecordCreatedBy,
                  recordUpdated: el.RecordUpdated,
                  recordUpdatedBy: el.RecordUpdatedBy,
                } as TOCItemsType)
            ),
          };
        });

        return versionsMapped;
      }
    }
    return response.data;
  }
);

export const getAllTocFacilitiesAsync = createAsyncThunk(
  "toc/getAllTocFacilitiesAsync",
  async (intakeId: string) => {
    const response = await axiosPrivate.get(
      `${
        getConfig().efusionBase
      }/api/v2/ExtProvider/getallByIntake/${intakeId}/false/true/false/false/true`,
      { params: {} }
    );
    return response.data.map(
      (el: any) =>
        ({
          id: el.ID,
          firstName: el.FirstName,
          lastName: el.LastName,
          providerName: el.ProviderName,
          providerPhone: el.ProviderPhone,
          preferredProvider: el.PreferredProvider,
        } as IFacility)
    );
  }
);

export const approveTocRevisionAsync = createAsyncThunk(
  "toc/approveTocRevision",
  async (requestPayload: IApproveTocPayload) => {
    const response = await axiosPrivate.put(
      `${getConfig().taskManagementBase}/api/v1/physician/toc/approve`,
      requestPayload
    );
    return response.data;
  }
);

export const getPacTypesAsync = createAsyncThunk(
  "toc/getPacTypesAsync",
  async () => {
    const response = await axiosPrivate.get(
      `${getConfig().taskManagementBase}/api/v1/physician/toc/pactypes`,
      {}
    );
    return response.data;
  }
);
