import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { axiosPrivate } from "shared/api/axios-wrapper.service";
import { CUSTOM_EVENT_NAMES } from "shared/constant/constants";
import {
  dispatchCustomEvent,
  setCookie,
} from "shared/methods/utilityFunctions";
import { getAccessTokenAsync } from "state/features/authentication/authentication.action";
import {
  getAuth,
  getTokensFromCookies,
  setAuthenticationTokens,
} from "state/features/authentication/authentication.slice";
import { useAppDispatch } from "state/store";

const useAxiosAuthenticated = () => {
  const dispatch = useAppDispatch();
  const { auth } = useSelector(getAuth);
  const history = useHistory();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (request: any) => {
        if (auth.jwtToken && !request._retry) {
          request.headers = {
            ...request.headers,
            Authorization: `Bearer ${auth.jwtToken}`,
          };
        }
        return request;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401) {
          try {
            prevRequest._retry = true;

            const { jwtToken: newAccessToken } = await dispatch(
              getAccessTokenAsync({ refreshToken: auth.refreshToken })
            ).unwrap();

            const tokens = getTokensFromCookies();
            setCookie("authTokens", {
              ...tokens,
              jwtToken: newAccessToken,
            });
            dispatch(
              setAuthenticationTokens({ ...auth, jwtToken: newAccessToken })
            );
            prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;

            return axiosPrivate(prevRequest);
          } catch (err) {
            dispatchCustomEvent(CUSTOM_EVENT_NAMES.CLEAR_REDUX_DATA);
            history.push("/");
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth]);

  return axiosPrivate;
};

export default useAxiosAuthenticated;
